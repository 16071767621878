<template>
  <div>
    <h1>福祉事業部 勤務実績</h1>

    <v-radio-group
      v-model="select"
      row
      label="取得種別:"
      @change="getData(false)"
    >
      <v-radio
        label="ユーザー別"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.User"
      ></v-radio>
      <v-radio
        label="事業所別"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.Office"
      ></v-radio>
    </v-radio-group>

    <v-card class="pa-5" flat outlined>
      <v-autocomplete
        v-model="user"
        v-if="select == Selects.User"
        :items="users"
        outlined
        label="ユーザー選択"
        item-text="name"
        item-value="code"
        chips
      >
      </v-autocomplete>
      <v-autocomplete
        v-model="shozoku"
        v-if="select == Selects.Office"
        :items="shozokus"
        outlined
        label="事業所選択"
        item-text="name"
        item-value="code"
        chips
      >
      </v-autocomplete>
      <DatePicker
        v-model="date"
        label="対象年月"
        v-if="select == Selects.User"
        prepend-inner-icon="mdi-calendar"
        type="month"
        outlined
        next
        previous
      />
      <v-row v-if="select == Selects.Office">
        <v-col cols="5">
          <DatePicker
            v-model="dateFrom"
            label="対象年月From"
            prepend-inner-icon="mdi-calendar"
            type="day"
            v-if="select == Selects.Office"
            outlined
          />
        </v-col>
        <v-col cols="2"><p class="range-text">〜</p></v-col>
        <v-col cols="5">
          <DatePicker
            v-model="dateTo"
            label="対象年月To"
            prepend-inner-icon="mdi-calendar"
            type="day"
            v-if="select == Selects.Office"
            outlined
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <p v-if="monthoverflag == true">
          <font size="5" color="red"
            >月の勤務時間数が所定時間より小さくなっています。</font
          >
        </p>
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="getData(true)">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5" v-if="!$isEmpty(cols)" flat outlined>
      <v-toolbar class="mb-2" flat>
        <v-toolbar-title> <h3 class="headline">勤務実績</h3></v-toolbar-title>
        <template #extension>
          <Btn
            class="mr-2"
            color="success"
            icon="plus"
            text
            outlined
            @click="add"
            :disabled="isShowCalc()"
            >追加</Btn
          >
          <Btn
            class="mx-2"
            color="error"
            icon="delete"
            text
            outlined
            @click="remove"
            :disabled="!selected.length || isShowCalc()"
            >削除</Btn
          >
          <Btn
            class="mx-2"
            color="success"
            icon="mdi-plus-box-multiple-outline"
            text
            outlined
            @click="bulkadd"
            :disabled="isShowCalc()"
            >一括追加</Btn
          >
          <Btn
            class="mx-2"
            color="success"
            icon="mdi-pencil-box-multiple-outline"
            text
            outlined
            :disabled="!selected.length || isShowCalc()"
            @click="bulkfix"
            >一括更新</Btn
          >
          <Btn
            class="mx-2"
            color="info"
            icon="mdi-arrow-bottom-right-thick"
            text
            outlined
            :disabled="isShowCalc()"
            @click="createByShift"
            >シフトから実績を追加</Btn
          >
          <Btn
            class="mx-2"
            icon="mdi-monitor-edit"
            color="primary"
            text
            outlined
            v-show="select == Selects.User"
            :disabled="!$isEmpty(summary)"
            @click="summaryfix(false)"
            >給与合計追加</Btn
          >
        </template>
      </v-toolbar>
      <v-toolbar class="mb-2" flat v-show="select == Selects.User">
        <v-spacer></v-spacer>
        <Btn
          icon="mdi-arrow-left-bold"
          color="#ff6666"
          v-show="backForCalc"
          text
          outlined
          @click="backCalc"
          class="mx-5"
        >
          一括計算に戻る
        </Btn>
        <Btn
          icon="mdi-arrow-left-bold"
          color="#ff6666"
          v-show="backForShift"
          text
          outlined
          @click="backShift"
          class="mx-5"
        >
          シフト表に戻る
        </Btn>
        <Btn
          icon="calculator"
          color="primary"
          v-show="select == Selects.User"
          text
          outlined
          :disabled="isShowCalc()"
          @click="calctime"
          class="mx-5"
        >
          時間計算
        </Btn>
        <Btn
          icon="calculator"
          color="primary"
          v-show="select == Selects.User"
          text
          outlined
          :disabled="isShowCalc()"
          @click="calc"
        >
          金額計算
        </Btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <vue-good-table
          ref="table"
          class="detail-table"
          :columns="cols"
          :rows="rows"
          :row-style-class="rowStyleClass"
          :select-options="options.select"
          styleClass="vgt-table striped bordered condensed"
          @on-selected-rows-change="onChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'remarks'">
              <v-chip
                class="mx-1"
                small
                v-for="remark in props.row.remarks"
                :key="remark"
              >
                {{ remark }}
              </v-chip>
            </span>
            <!-- Actions -->
            <span v-else-if="props.column.field === 'actions'">
              <Btn color="info" text small @click="fix(props.row)">修正</Btn>
            </span>
          </template>
        </vue-good-table>

        <v-text-field
          v-if="select == Selects.User && summary"
          v-model="usernm"
          readonly
        >
        </v-text-field>

        <table
          v-if="select == Selects.User && summary"
          class="mt-5 detail-table"
        >
          <caption class="headline">
            合計
          </caption>
          <tr class="detail-table-header">
            <th v-for="(header, i) in summaryheader" :key="i">
              {{ header.text }}
            </th>
          </tr>
          <tr v-for="(sum, i) in summary" :key="i">
            <td v-for="header in summaryheader" :key="header.value + i">
              {{ editComma(header.value, sum[header.value]) }}
            </td>
            <td
              v-for="(s, key) in sum"
              :key="key"
              :class="isDispSummary(key, s)"
            ></td>
          </tr>
        </table>

        <v-row
          class="mt-5 d-flex justify-content-end"
          v-if="!$isEmpty(summary)"
          flat
          outlined
        >
          <Btn
            icon="mdi-arrow-left-bold-hexagon-outline"
            color="#003399"
            class="mx-5 mb-5"
            text
            outlined
            :disabled="isDisabledApprovalUser()"
            @click="moveNextemployee(false)"
            >前の確定者へ</Btn
          >
          <Btn
            icon="mdi-arrow-right-bold-hexagon-outline"
            color="#003399"
            class="mx-5 mb-5"
            text
            outlined
            :disabled="isDisabledApprovalUser()"
            @click="moveNextemployee(true)"
            >次の確定者へ</Btn
          >
          <p v-if="monthoverflag == true">
            <font size="5" color="red"
              >月の勤務時間数が所定時間より小さくなっています。</font
            >
          </p>
          <v-spacer></v-spacer>
          <Btn
            icon="mdi-monitor-edit"
            color="primary"
            text
            outlined
            :disabled="isDisabledConfirm('1') && isShowCalc()"
            @click="summaryfix(true)"
            class="mb-5"
            >合計修正</Btn
          >
          <Btn
            icon="mdi-monitor-off"
            color="warning"
            text
            outlined
            class="mb-5, mx-5"
            :disabled="isDisabledConfirm('0')"
            @click="salaryConfirm('0')"
            >給与確定取消</Btn
          >
          <Btn
            icon="mdi-monitor-lock"
            color="success"
            text
            outlined
            class="mb-5"
            :disabled="isDisabledConfirm('1')"
            @click="salaryConfirm('1')"
            >給与確定</Btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";

// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

import Api from "@/mixins/api";
import * as Dummy from "./dummy";
import AttendanceFixDialogVue from "./dialogs/WelfareAttendanceFixDialog.vue";
import AttendanceDetail from "@/models/AttendanceDetail";
import WelfareAttendanceSummaryFixDialog from "@/views/dialogs/WelfareAttendanceSummaryFixDialog.vue";
import AttendanceBulkAddDialog from "./dialogs/AttendanceBulkAddDialog.vue";
import AttendanceBulkFixDialog from "./dialogs/AttendanceBulkFixDialog.vue";
import AttendanceOfficeFixDialog from "./dialogs/WelfareAttendanceOfficeFixDialog.vue";
import AttendanceBulkOfficeAddDialog from "./dialogs/AttendanceBulkOfficeAddDialog.vue";
import { BUSYO_CODE as BusyoCode } from "@/defines";
import moment from "moment";
const Selects = {
  User: 0,
  Office: 1,
  Me: 2
};

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const Users = [];

const welfareHeader = [
  {
    value: "nightguardcount",
    text: "宿直 回数"
  },
  {
    value: "nightguardallowance",
    text: "宿直 手当"
  },
  {
    value: "emergencycount",
    text: "緊急 出動 回数"
  },
  {
    value: "emergencyallowance",
    text: "緊急 出動 手当"
  },
  {
    value: "midnighttelcount",
    text: "夜間 電話 回数"
  },
  {
    value: "midnighttelallowance",
    text: "夜間 電話 手当"
  },
  {
    value: "buWelfareKaigo",
    text: "BU 処遇 介護"
  },
  {
    value: "buWelfareSyogai",
    text: "BU 処遇 障害"
  },
  {
    value: "welfarekaigo",
    text: "処遇 改善 介護"
  },
  {
    value: "welfaresyogai",
    text: "処遇 改善 障害"
  },
  {
    value: "specialWelfareKaigo",
    text: "特定 処遇 介護"
  },
  {
    value: "specialWelfareSyogai",
    text: "特定 処遇 障害"
  }
];

export default {
  name: "WelfareAttendanceDetails",
  mixins: [Common, ShowDialogs, Api],
  components: {
    VueGoodTable
  },
  data: () => ({
    menu: false,
    date:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    dateFrom: DATENOW,
    dateTo: DATENOW,
    select: Selects.User,
    records: [],
    isConfirm: false,
    attendances: { rows: [], columns: [] },
    summaries: { rows: [], columns: [] },
    total: { rows: [], columns: [] },
    cols: [],
    rows: [],
    summaryheader: [],
    summary: [],
    users: Users,
    user: Users[0],
    Selects,
    options: {
      select: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true
      },
      search: { enabled: true }
    },
    selected: [],
    shozokus: [],
    shozoku: { code: "", name: "" },
    backForCalc: false,
    backForShift: false,
    usernm: "",
    monthoverflag: false,
    duplicationflag: false
  }),
  computed: {
    SummaryHeader() {
      return null;
    },
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  methods: {
    rowStyleClass(row) {
      console.log("rowStyleClass", row);
      if (row.officialholiday == "1") {
        return row.officialholiday === "1" ? "orange" : "";
      } else {
        if (row.remarks === "1") {
          return "yellow";
        } else if (row.remarks === "2") {
          if (this.duplicationflag == false) {
            this.duplicationflag = true;
          }
          return "red";
        } else {
          return "";
        }
        //return row.remarks === "1" ? "yellow" : "";
      }
    },
    async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 21);
      let targetdt = new Date(this.Year, this.Month, this.Day);

      const m = moment(targetdt);
      if (targetdt == startmonth) {
        // targetdt.setMonth(targetdt.getMonth() + 1);
        targetdt = m.add(1, "M").toDate();
      } else if (targetdt > startmonth && targetdt.getDate() < 21) {
        //targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      } else if (targetdt < startmonth && targetdt.getDate() < 21) {
        // targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      }

      let targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth())).slice(-2);

      if (Number(targetdt.getMonth()) == 0) {
        const m2 = moment(targetdt);
        targetdt = m2.add(-1, "M").toDate();
        targetMonth =
          targetdt.getFullYear() +
          "-" +
          ("00" + Number(targetdt.getMonth() + 1)).slice(-2);
      }

      return targetMonth;
    },
    async confirm() {
      this.isConfirm = await this.$confirm(
        "勤務表を提出します。\nよろしいですか？"
      );
    },
    async getData(api) {
      let data;
      this.monthoverflag = false;
      this.duplicationflag = false;
      switch (this.select) {
        case Selects.Me:
        case Selects.User:
        default: {
          if (api) {
            this.$loading();

            try {
              data = await this.getRecordByUser();
              // TODO 暫定対応:シフトあり、実績なしのデータを非表示
              const bodies = [];
              data.body.forEach(e => {
                if (e.recordid !== null) bodies.push(e);
              });
              data.body = bodies;
              const selectuser = this.users.filter(e => e.code === this.user);
              console.log("selectuser", selectuser);
              this.usernm = selectuser[0].name;
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getPrivateRostar();
          }
          break;
        }
        case Selects.Office:
          if (api) {
            this.$loading();
            try {
              data = await this.getRecordByOffice();
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getOfficeRostar();
          }
          break;
      }
      await this.setAttendanceDetails(data);
    },
    async getRecordByUser() {
      const targetMonth = await this.getTargetMonth();
      const param = {
        incode: this.user.code ? this.user.code : this.user,
        targetmonth: targetMonth,
        searchkbn: "1"
      };
      console.log("getRecordByUser", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query=" + encode);
    },
    async getRecordByOffice() {
      const param = {
        affiliationId: this.shozoku.code ? this.shozoku.code : this.shozoku,
        attendanceDateFrom: this.dateFrom,
        attendanceDateTo: this.dateTo,
        searchkbn: "1"
      };
      console.log("getRecordByOffice", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query-office=" + encode);
    },
    async fix(item) {
      console.log("fix", item);

      if (item.officialholiday == "1") {
        this.$error("公休日のレコードは、編集できません。");
        return;
      }

      if ((await this.isShowCalc()) == true) {
        this.$error("既に給与確定済のため、編集できません。");
        return;
      }

      let result;
      if (this.select === this.Selects.User) {
        item.employee = this.user.code ? this.user.code : this.user;
        result = await this.$showDialog(AttendanceFixDialogVue, {
          args: item
        });
      } else {
        result = await this.$showDialog(AttendanceOfficeFixDialog, {
          args: item
        });
      }

      if (result) {
        await this.getData(true);
      }
    },
    onChange(e) {
      console.log(e);
      this.selected = e.selectedRows;
    },
    async add() {
      console.log("add");
      const ts = DATE.getTime();

      let usercode = "";
      if (this.select === this.Selects.User) {
        usercode = this.user.code ? this.user.code : this.user;
      }

      let officecode = "";
      if (this.select === this.Selects.Office) {
        officecode = this.shozoku.code ? this.shozoku.code : this.shozoku;
      }

      const item = new AttendanceDetail(
        "",
        usercode,
        officecode,
        "",
        "",
        DATE,
        ts,
        "",
        "",
        "0",
        "",
        "",
        "",
        "",
        "",
        "",
        "0",
        "0",
        "0",
        "0"
      );

      let result;
      if (this.select === this.Selects.User) {
        result = await this.$showDialog(AttendanceFixDialogVue, {
          args: item
        });
      } else {
        result = await this.$showDialog(AttendanceOfficeFixDialog, {
          args: item
        });
      }

      if (result) {
        await this.getData(true);
      }
    },
    async edit() {
      console.log("edit");

      const selected = this.selected;

      const result = await this.$AttendanceFix(selected);
      if (result) {
        selected.forEach(select => {
          Object.keys(result).forEach(key => {
            if (result[key]) select[key] = result[key];
          });
        });
      }
    },
    async remove() {
      console.log("remove");
      const result = await this.$deleteConfirm(
        this.selected.length +
          "件 削除します。\nよろしいですか？ \n 公休日または、給与確定済の場合は、削除されません。"
      );

      if (result) {
        const ids = [];
        this.selected.forEach(s => {
          if (s.officialholiday != "1") {
            ids.push(s.recordid);
          }
        });

        if (ids.length == 0) {
          this.$info("削除対象データが有りませんでした。", "勤務実績");
          return;
        }

        this.$loading();

        try {
          const param = ids.join(",");
          const encode = encodeURI(param);
          await this.$del(this.Paths.attendance, "ids=" + encode);

          console.log(this.rows);
          const rows = this.rows;
          const indexes = this.selected.map(select => select.originalIndex);
          this.rows = rows.filter((row, i) => !indexes.includes(i));

          await this.$info("更新しました。", "勤務実績");
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
          await this.getData(true);
        }
      }
    },
    async bulkadd() {
      console.log("bulkadd");

      const targetMonth = await this.getTargetMonth();

      const targetFromDate = new Date(
        parseInt(targetMonth.split("-")[0]),
        parseInt(targetMonth.split("-")[1]),
        21
      );

      const targetToDate = new Date(
        targetFromDate.getFullYear(),
        targetFromDate.getMonth() + 1,
        20
      );

      let result = false;
      if (this.select === this.Selects.User) {
        const incode = this.user.code ? this.user.code : this.user;
        const user = this.users.filter(e => e.code === incode);

        const param = {
          incode: incode,
          username: user[0].name,
          fromdate: targetFromDate,
          todate: targetToDate
        };

        result = await this.$showDialog(AttendanceBulkAddDialog, {
          args: param
        });
      } else {
        const officecode = this.shozoku.code ? this.shozoku.code : this.shozoku;
        const shozoku = this.shozokus.filter(e => e.code === officecode);
        const param = {
          officecode: officecode,
          officename: shozoku[0].name,
          fromdate: targetFromDate,
          todate: targetToDate
        };
        result = await this.$showDialog(AttendanceBulkOfficeAddDialog, {
          args: param
        });
      }

      if (result) {
        await this.getData(true);
      }
    },
    async bulkfix() {
      const incode = this.user.code ? this.user.code : this.user;
      const user = this.users.filter(e => e.code === incode);

      const recordIds = [];
      this.selected.forEach(s => {
        if (s.officialholiday != "1") {
          recordIds.push(s.recordid);
        }
      });

      if (recordIds.length == 0) {
        await this.$info(
          "更新対象となるデータがありません。 \n ※公休日は、編集できません。"
        );
        return;
      }

      const param = {
        incode: incode,
        username: user[0].name,
        recordIds: recordIds
      };

      const result = await this.$showDialog(AttendanceBulkFixDialog, {
        args: param
      });

      if (result) {
        await this.getData(true);
      }
    },
    async createByShift() {
      console.log("createByShift");
      let targetFrom, targetTo;

      if (this.select === Selects.Office) {
        targetFrom = new Date(
          this.dateFrom.split("-")[0],
          this.dateFrom.split("-")[1] - 1,
          this.dateFrom.split("-")[2]
        );

        targetTo = new Date(
          this.dateTo.split("-")[0],
          this.dateTo.split("-")[1] - 1,
          this.dateTo.split("-")[2]
        );

        if (targetFrom > targetTo) {
          this.$warning("開始日の指定が正しくありません");
          return;
        }
      }
      const confirm = await this.$confirm(
        "シフトから実績を作成します。\nよろしいですか？"
      );
      if (!confirm) return;
      if (this.select === Selects.Office) {
        console.log(targetTo, DATE);
        if (targetTo >= DATE) {
          const f = await this.$confirm(
            "終了日に本日以降の日付が設定されています。\nよろしいですか？"
          );
          if (!f) return;
        }
      }
      let param = {};
      if (this.select === Selects.User) {
        const targetMonth = await this.getTargetMonth();
        param = {
          requestType: "0",
          userIncode: this.user.code ? this.user.code : this.user,
          targetMonth: targetMonth
        };
      }
      if (this.select === Selects.Office) {
        param = {
          requestType: "1",
          shozokuIncode: this.shozoku.code ? this.shozoku.code : this.shozoku,
          targetDateFrom: this.dateFrom,
          targetDateTo: this.dateTo
        };
      }
      this.$loading();
      try {
        await this.$post(this.Paths.shiftAttendance, param);
        await this.$info("更新しました。");
        let data = [];
        if (this.select === Selects.User) {
          data = await this.getRecordByUser();
        }
        if (this.select === Selects.Office) {
          data = await this.getRecordByOffice();
        }
        const bodies = [];
        data.body.forEach(e => {
          if (e.recordid !== null) bodies.push(e);
        });
        data.body = bodies;
        await this.setAttendanceDetails(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async calctime() {
      if (this.duplicationflag === true) {
        await this.$error(
          "時間が重複しています。 \n 解消してから \n 再度、実行してください。"
        );
        return;
      }

      const result = await this.$confirm(
        "時間計算を実行します。\nよろしいですか？"
      );
      if (!result) return;

      this.$loading();

      try {
        console.log("calctime");
        const data = await this.postForCalc(
          this.Paths.attendanceCalcTimeFukushi
        );
        // TODO 暫定対応:シフトあり、実績なしのデータを非表示
        const bodies = [];
        data.body.forEach(e => {
          if (e.recordid !== null) bodies.push(e);
        });
        data.body = bodies;
        await this.setAttendanceDetails(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async calc() {
      if (this.duplicationflag === true) {
        await this.$error(
          "時間が重複しています。 \n 解消してから \n 再度、実行してください。"
        );
        return;
      }

      const result = await this.$confirm(
        "金額計算を実行します。\nよろしいですか？"
      );
      if (!result) return;

      this.$loading();
      try {
        console.log("calc");
        const data = await this.postForCalc(this.Paths.attendanceCalcFukushi);
        // TODO 暫定対応:シフトあり、実績なしのデータを非表示
        const bodies = [];
        data.body.forEach(e => {
          if (e.recordid !== null) bodies.push(e);
        });
        data.body = bodies;
        await this.setAttendanceDetails(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async postForCalc(path) {
      console.log("postForCalc", path);

      const targetMonth = await this.getTargetMonth();

      const targetFromDate = new Date(
        parseInt(targetMonth.split("-")[0]),
        parseInt(targetMonth.split("-")[1]),
        21
      );

      const targetToDate = new Date(
        targetFromDate.getFullYear(),
        targetFromDate.getMonth() + 1,
        20
      );

      const param = {
        employeeId: this.user.code ? this.user.code : this.user,
        workdatefrom: await this.getTargetDateString(targetFromDate),
        workdateto: await this.getTargetDateString(targetToDate),
        targetmonth: targetMonth,
        searchkbn: "1"
      };

      return await this.$post(path, param);
    },
    async getUsers() {
      console.log("users");
      try {
        const result = await this.$get(this.Paths.shainSorted);

        if (result) {
          this.users.length = 0;
          this.users = result;
          this.user = result[0].code;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShozoku() {
      console.log("getShozoku");
      try {
        const param = {
          code1: BusyoCode.WELFARE
        };
        const encode = encodeURI(JSON.stringify(param));
        const result = await this.$get(this.Paths.shozoku, "query=" + encode);
        if (result) {
          this.shozokus.length = 0;
          this.shozokus = result;
          this.shozoku = result[0].code;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async summaryfix(edit) {
      console.log("summaryfix");

      const params = await this.getSummaryFixParameters(edit);
      if (edit) params.summary = this.summary[this.summary.length - 1];

      const result = await this.$showDialog(WelfareAttendanceSummaryFixDialog, {
        args: params
      });

      if (result && !edit) {
        await this.getData(true);
      }
    },
    async getTargetDateString(targetDate) {
      return (
        targetDate.getFullYear() +
        "-" +
        targetDate.getMonth() +
        "-" +
        targetDate.getDate()
      );
    },
    async setAttendanceDetails(data) {
      const headers = data.headers.map(header => ({
        label: header.text,
        field: header.value,
        sortable: !["remarks"].includes(header.value)
      }));

      headers.push({ label: "操作", field: "actions", sortable: false });

      const date = headers.find(col => col.field === "date");
      if (date) {
        date.type = "date";
        date.dateInputFormat = "T"; //"yyyy-MM-dd"; // expects 2018-03-16
        date.dateOutputFormat = "M 月 dd 日"; // outputs Mar 16th 2018
      }
      const bodies = [];
      data.body.forEach(e => {
        if (e.recordid !== null) bodies.push(e);
      });
      this.cols = headers;
      this.rows = data.body;
      const summaryheadders = data.summaryheadders.concat(welfareHeader);
      this.summaryheader = summaryheadders;
      this.summary = data.summary;
    },
    async salaryConfirm(confirmFlag) {
      this.$loading();
      try {
        console.log("salaryConfirm", confirmFlag);

        if (this.duplicationflag === true && confirmFlag === "1") {
          await this.$error(
            "時間が重複しています。 \n 解消してから \n 再度、実行してください。"
          );
          return;
        }

        const dt = new Date(
          parseInt(this.date.split("-")[0]),
          parseInt(this.date.split("-")[1]),
          1
        );
        dt.setDate(dt.getDate() - 1);
        const paymentdate =
          dt.getFullYear() +
          "-" +
          ("00" + Number(dt.getMonth() + 1)).slice(-2) +
          "-" +
          ("00" + dt.getDate()).slice(-2);

        const param = {
          paymentdate: paymentdate,
          employeeId: this.user.code ? this.user.code : this.user,
          isConfirm: confirmFlag
        };

        await this.$put(this.Paths.attendanceSalaryConfirm, param);
        this.summary.filter(e =>
          e.affiliationName === "合計" ? (e.isconfirm = confirmFlag) : false
        );

        await this.$info("更新しました。");

        const data = await this.getRecordByUser();
        await this.setAttendanceDetails(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    editComma(key, val) {
      if (key === "affiliationName") return val;
      return Number(val).toLocaleString(undefined, {
        maximumFractionDigits: 20
      });
    },
    isDispSummary(key, s) {
      console.log(key, "key");
      console.log(s, "s");
      if (key === "monthhourflag") {
        console.log(this.monthoverflag, "monthoverflag");
        if (s == "1" && this.monthoverflag == false) {
          this.monthoverflag = true;
        }
      }
      return "disp-none";
    },
    isDisabledConfirm(target) {
      console.log("isDisabledConfirm", target);
      const sumrow = this.summary.filter(e => e.affiliationName === "合計");
      console.log(sumrow);
      if (!sumrow) return true;
      console.log(sumrow[0].isconfirm);
      const authority = this.$store.state.authority;
      //福祉事業部管理者または、総務部しか確定を押せないようにする。
      if (authority === "3") {
        return true;
      }
      return sumrow[0].isconfirm === target ? true : false;
    },
    isShowCalc() {
      console.log("isShowCalc");
      try {
        const sumrow = this.summary.filter(e => e.affiliationName === "合計");
        console.log(sumrow);
        if (!sumrow) return false;
        console.log(sumrow[0].approvalStatus);
        if (sumrow[0].appoverflag === "1") {
          const authority = this.$store.state.authority;
          if (authority == "1") {
            return true;
          }
        } else if (sumrow[0].appoverflag === "2") {
          return true;
        }
      } catch (e) {
        return false;
      }

      return false;
    },
    isDisabledApprovalUser() {
      console.log("isDisabledApprovalUser", this.$route.params.targetemployees);
      return this.$route.params.targetemployees &&
        this.$route.params.targetemployees.length > 0
        ? false
        : true;
    },
    async moveNextemployee(next) {
      console.log("moveNextemployee", next, this.$route.params);

      const index = this.$route.params.targetemployees.findIndex(
        e => e.incode === this.user
      );
      let nextIndex = 0;
      if (next) {
        if (index === this.$route.params.targetemployees.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex = index + 1;
        }
      } else {
        if (index === 0) {
          nextIndex = this.$route.params.targetemployees.length - 1;
        } else {
          nextIndex = index - 1;
        }
      }

      this.user = String(this.$route.params.targetemployees[nextIndex].incode);
      await this.getData(true);
    },
    setdate() {
      this.date = this.$getCrossServiceDate();
    },
    async getSummaryFixParameters(edit) {
      const userincode = this.user.code ? this.user.code : this.user;
      const selectuser = this.users.filter(e => e.code === userincode);

      const startmonth = new Date(this.Year, this.Month, 21);
      const targetdt = new Date(this.Year, this.Month, this.Day);

      if (targetdt == startmonth) {
        targetdt.setMonth(targetdt.getMonth() + 1);
      } else if (targetdt > startmonth && targetdt.getDate() < 21) {
        targetdt.setMonth(targetdt.getMonth() - 1);
      } else if (targetdt < startmonth && targetdt.getDate() < 21) {
        targetdt.setMonth(targetdt.getMonth() - 1);
      }

      const targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth() + 1)).slice(-2);

      const params = {
        targetmonth: targetMonth,
        user: selectuser[0],
        edit: edit,
        summary: []
      };

      return params;
    },
    backCalc() {
      const path = "/welfare/for-calc";
      this.$router.push({
        path: path,
        query: { filter: this.$route.params.filter }
      });
    },
    backShift() {
      const path = "/welfare/shifttable";
      this.$router.push({
        path: path,
        query: { filter: this.$route.params.filter }
      });
    }
  },
  async created() {
    console.log("created", this.date);

    this.$loading();
    try {
      await this.getUsers();
      await this.getShozoku();
      this.setdate();
    } finally {
      this.$unloading();
    }

    this.monthoverflag = false;
    this.duplicationflag = false;

    if (this.$route.params.targetdate && this.$route.params.userincode) {
      console.log("params", this.$route.params);
      this.select = Selects.User;
      this.user = this.$route.params.userincode;
      this.date = this.$route.params.targetdate;
      console.log("params exists", this.user, this.date);
      if (this.$route.params.type === "0") {
        this.backForCalc = true;
      } else if (this.$route.params.type === "1") {
        this.backForShift = true;
      }

      await this.getData(true);
    }
  },
  mounted() {
    console.log("mounted");
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
}

table th:first-child {
  border-radius: 5px 0 0 0;
}

table th:last-child {
  border-radius: 0 5px 0 0;
  border-right: 1px solid #3c6690;
}

table th {
  text-align: center;
  color: white;
  background: linear-gradient(#829ebc, #225588);
  border-left: 1px solid #3c6690;
  border-top: 1px solid #3c6690;
  border-bottom: 1px solid #3c6690;
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
  width: 5%;
  padding: 10px 0;
}

table td {
  text-align: center;
  border-left: 1px solid #a8b7c5;
  border-bottom: 1px solid #a8b7c5;
  border-top: none;
  box-shadow: 0px -3px 5px 1px #eee inset;
  width: 5%;
  padding: 10px 0;
}

table td:last-child {
  border-right: 1px solid #a8b7c5;
}

table tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

table tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}
table.vgt-table {
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}

.detail-table {
  font-size: 12px;
}

.detail-table-header {
  word-break: keep-all;
}

.disp-none {
  display: none;
}

.range-text {
  text-align: center;
  line-height: 50px;
  height: 50px;
}
</style>
